// i18next-extract-mark-ns-start products-fearce-tbc

import { FEARCE_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductFearcePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							FEARCE offers unique capabilities for the prediction of
							temperatures in IC engines. In any powertrain
							analysis, the accurate determination of the temperature
							distribution at key operating conditions is critical to
							component design. FEARCE has been developed specifically
							to address these needs and provides a number of tools
							for the application of thermal-boundary conditions to a
							finite element (FE) analysis.
						</p>
						<h2>Coolant boundary conditions</h2>
						<p>
							For coolant-side boundary conditions, FEARCE can quickly
							and efficiently map fluid temperature and heat-transfer
							coefficients (HTCs) from computational fluid dynamics
							(CFD) analysis onto the appropriate coolant surfaces of
							the FE models. This can be done by either linking directly to
							<a href="https://realis-simulation.com//products/vectis/"> VECTIS</a>,
							or by mapping HTC values from any major CFD solver.
							In addition to linking directly to VECTIS to extract results, the FEARCE
							graphical user interface (GUI) can display the VECTIS
							models, allowing an engineer to visualize the results
							alongside the loaded models.
						</p>
						<p> The effect of nucleate boiling can be simulated as part of an iterative solution using a map of heat transfer coefficients
							dependent on the resultant wall temperature.
							In the locations where the resultant wall temperature is predicted above the boiling point,
							the heat transfer coefficient is adjusted (increased) and used for the next iteration.
						</p>

						<h2>In-cylinder boundary conditions</h2>
						<p>
							For in-cylinder thermal boundary conditions
							<a href="https://realis-simulation.com//products/fearce/thermal-analysis-for-IC-engines"> FEARCE-Vulcan</a>{' '}
							considers all the heat paths of the power cylinder using physical models and semi-empirical correlations,
							without necessarily the need for CFD analysis. Alternatively,
							results from <a href="https://realis-simulation.com//products/vectis/"> VECTIS</a>{' '}
							or other CFD solvers can be imported to predict gas-side HTCs resulting from combustion.
							FEARCE then calculates cycle averaged loads from the imported full-cycle values to calculate steady-state temperatures.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>HTCs mapped directly from CFD results</li>
							<li>Links directly with VECTIS for results extraction and visualization</li>
							<li>HTC and heat-flux profiles automatically generated for cylinder bores and flame faces</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{FEARCE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductFearcePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-fearce-tbc", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/fearce-hero.jpg" }) {
		...imageBreaker
	}
}
`;